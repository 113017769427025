import React from "react";

import { Input, Button } from '@builder/component-library';

// Customizable Area Start
import { Typography, Box, Link, List } from '@mui/material';
import { otherPagesMobileUiBanner } from "../../landingpage/src/assets";


// Customizable Area End

import ServicesPageController, {
    Props,
    configJSON,
} from "./ServicesPageController";

export default class ServicesPage extends ServicesPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    baseURL = require("../../../framework/src/config.js").baseURL;
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                <Box component={"img"} className='otherPagesMobileUiBannerStyle' src={otherPagesMobileUiBanner} alt="Banner_Img"
                />
                <Box sx={webStyle.servicesWrapper}>
                    <Typography sx={webStyle.servicesHeader} data-test-id="title">{this.state.servicesPageContentData?.title}</Typography>
                    <Box component="span" sx={webStyle.servicesDescription}
                        dangerouslySetInnerHTML={{ __html: this.state.servicesPageContentData?.content }}>
                    </Box>
                </Box>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const webStyle = {
    servicesBannerImage: {
        height: 'calc(100vh - 190px)',
        width: '100%',
    },
    servicesWrapper: {
        backgroundColor: "#E5F4FB",
        p: "80px 80px",
        "@media (max-width: 810px)": {
            p: "40px 16px"
        }
    },
    servicesHeader: {
        fontSize: "30px",
        fontWeight: "800",
        color: "#12629A",
        fontFamily: "'Nunito Sans', sans-serif",
        lineHeight: "35.2px",
        mb: "16px",
        "@media (max-width: 810px)": {
            fontSize: "23px",
            lineHeight: "25.3px",
            fontWeight: "900",
            letterSpacing: "0",
        }
    },
    servicesDescription: {
        fontSize: "18px",
        fontWeight: "400",
        lineHeight: "29.12px",
        fontFamily: "'Nunito Sans', sans-serif",
        "> ul:nth-of-type(n)": {
            background: "#FFFFFF",
            boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.1)",
            m: "16px 0",
            p: "16px 40px",
            "@media (max-width: 810px)": {
                p: "24px 48px"
            }  
        }
    },
    servicesDescriptionHeader: {
        fontSize: "18px",
        fontWeight: "700",
        lineHeight: "29.12px",
        fontFamily: "'Nunito Sans', sans-serif",
    },
    servicesDescriptionLink: {
        fontSize: "18px",
        fontWeight: "400",
        lineHeight: "29.12px",
        fontFamily: "'Nunito Sans', sans-serif",
        textDecoration: "underline",
        color: "#12629A",
        cursor: "pointer"
    },
    servicesDescriptionSpecialtiesList: {
        background: "#FFFFFF",
        boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.1)",
        m: "16px 0",
        p: "16px 40px",
        "@media (max-width: 810px)": {
            p: "24px 48px"
        }
    },
    servicesBoxLists: {
        listStyleType: 'disc'
    },
    servicesMiddleHeader: {
        m: "24px 0",
        color: "#E5614B",
        fontSize: "26px",
        fontWeight: "700",
        lineHeight: "28.6px",
        fontFamily: "'Nunito Sans', sans-serif",
        "@media (max-width: 810px)": {
            fontSize: "22px",
        }
    }
}


// Customizable Area End

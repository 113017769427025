Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";

exports.noPropertyTitleText = "No properties";
exports.noPropertySubTitleText = "Click on the button below to start adding your properties";
exports.searchPlaceholderText = "Search";
exports.filterText = "Filter";
exports.addPropertyText = "Add property";
exports.propertyNameText = "Property Name";
exports.addressText = "Address";
exports.numberOfTenantsText = "# of Tenants";
exports.numberOfMetersText = "# of Meters";
exports.actionsText = "Actions";
exports.addTenantText = "Add Tenant";
exports.editText = "Edit";
exports.deleteText = "Delete";
// Customizable Area End
import React from "react";
// Customizable Area Start
import { Typography, Box, Tooltip } from "@mui/material";
import * as Yup from "yup";
import { FieldArray, Formik } from "formik";
import { errorIcon, addIcon, removeIcon, calendarIcon } from "./assets";
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// Customizable Area End

import AddTenantPageController, {
  Props,
  configJSON,
} from "./AddTenantPageController";
// Customizable Area Start
// Customizable Area End
export default class AddTenantPage extends AddTenantPageController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
  questionSchema = () => {
    return Yup.object().shape({
      tenantName: Yup.string().required("Tenant Name is required"),
      meters: Yup.array()
        .of(
          Yup.object().shape({
            type: Yup.string(),
            meterNumner: Yup.string(),
            lastReading: Yup.string(),
            lastReadingDate: Yup.string(),
          })
        )
        .required("Meteer-is required"),
    });
  };
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Box sx={webStyle.addPropertiesContainer}>
        <Box sx={webStyle.addPropertiesBlock}>
          <Box sx={webStyle.addPropertyFormContainer}>
            <Box>
              <Typography style={webStyle.formTitle}>Abstergo Ltd.</Typography>
              <Typography style={webStyle.formSubTitle}>
                New Hyde park, NY 11040
              </Typography>
            </Box>
            <Formik
              initialValues={{
                tenantName: "",
                meters: [
                  {
                    type: "",
                    meterNumner: "",
                    lastReading: "",
                    lastReadingDate: "",
                  },
                  {
                    type: "",
                    meterNumner: "",
                    lastReading: "",
                    lastReadingDate: "",
                  },
                  {
                    type: "",
                    meterNumner: "",
                    lastReading: "",
                    lastReadingDate: "",
                  },
                ],
              }}
              validateOnMount={true}
              validateOnChange={true}
              validationSchema={this.questionSchema}
              onSubmit={() => {
                this.setValidate(true);
              }}
              data-test-id="addPropertyForm"
            >
              {({ errors, values }) => (
                <form>
                  <Box sx={webStyle.formContainer}>
                    <Box sx={webStyle.mainFieldControl}>
                      <label style={webStyle.inputLabel} htmlFor={"tenantName"}>
                        {configJSON.tenantNameText}
                      </label>
                      <input
                        style={webStyle.inputElemnt}
                        data-test-id="tenantName"
                        type="text"
                      />
                      {this.state.validate && errors.tenantName && (
                        <Tooltip
                          open={Boolean(errors.tenantName)}
                          title={errors.tenantName}
                        >
                          <img
                            src={errorIcon}
                            alt=""
                            style={webStyle.errorIcon}
                          />
                        </Tooltip>
                      )}
                    </Box>
                    <FieldArray name="meters">
                      {(arrayHelpers: any) => (
                        <>
                          {values.meters.map((meter, index) => (
                            <Box sx={webStyle.meterBlcok}>
                              <Box sx={webStyle.meterTitle}>
                                <Typography sx={webStyle.meterTitleLabel}>
                                  {configJSON.meterTitleText}
                                  {index + 1}
                                </Typography>
                                <button
                                  style={webStyle.removeMeeterButton}
                                  type="button"
                                  onClick={() => {
                                    arrayHelpers.remove(index);
                                  }}
                                >
                                  <img
                                    src={removeIcon}
                                    alt=""
                                    style={webStyle.btnIcon}
                                  />
                                </button>
                              </Box>
                              <Box sx={webStyle.arrayFieldControl}>
                                <label
                                  style={webStyle.radioButtonTitleLabel}
                                  htmlFor={`meters.${index}.type`}
                                >
                                  {configJSON.typeText}
                                </label>
                                <Box sx={webStyle.radioButtnBlock}>
                                  <input
                                    style={webStyle.radioButtonElemnt}
                                    data-test-id={`meters.${index}.type.electrical`}
                                    name="meterType"
                                    value={"Electrical"}
                                    type="radio"
                                  />
                                  <label
                                    style={webStyle.radioButtonLabel}
                                    htmlFor="electrical"
                                  >
                                    {configJSON.electricalTypeText}
                                  </label>
                                </Box>
                                <Box sx={webStyle.radioButtnBlock}>
                                  <input
                                    style={webStyle.radioButtonElemnt}
                                    data-test-id={`meters.${index}.type.meter`}
                                    name="meterType"
                                    value={"Water"}
                                    type="radio"
                                  />
                                  <label
                                    style={webStyle.radioButtonLabel}
                                    htmlFor="meter"
                                  >
                                    {configJSON.meterTypeText}
                                  </label>
                                </Box>
                              </Box>
                              <Box sx={webStyle.arrayFieldControl}>
                                <label
                                  style={webStyle.inputLabel}
                                  htmlFor={`meters.${index}.meterNumner`}
                                >
                                  {configJSON.meterNumberText}
                                </label>
                                <input
                                  style={webStyle.inputElemnt}
                                  data-test-id={`meters.${index}.meterNumner`}
                                  type="text"
                                />
                              </Box>
                              <Box sx={webStyle.arrayFieldControl}>
                                <label
                                  style={webStyle.inputLabel}
                                  htmlFor={`meters.${index}.lastReading`}
                                >
                                  {configJSON.lastReadingText}
                                </label>
                                <input
                                  style={webStyle.inputElemnt}
                                  data-test-id={`meters.${index}.lastReading`}
                                  type="text"
                                />
                              </Box>
                              <Box sx={webStyle.arrayFieldControl}>
                                <label
                                  style={webStyle.inputLabel}
                                  htmlFor={`meters.${index}.lastReadingDate`}
                                >
                                  {configJSON.lastReadingDateText}
                                </label>
                                {/* <DatePicker /> */}
                                <input
                                  style={webStyle.inputElemnt}
                                  data-test-id={`meters.${index}.lastReadingDate`}
                                  type="text"
                                />
                                <img
                                  src={calendarIcon}
                                  alt=""
                                  style={webStyle.calendarIcon}
                                />
                              </Box>
                            </Box>
                          ))}
                          <Box sx={webStyle.fieldControl}>
                            <button
                              style={webStyle.addNewMeterButton}
                              type="button"
                              onClick={() => {
                                arrayHelpers.push({
                                  type: "",
                                  meterNumner: "",
                                  lastReading: "",
                                  lastReadingDate: "",
                                });
                              }}
                            >
                              <img
                                src={addIcon}
                                alt=""
                                style={webStyle.btnIcon}
                              />
                              <Typography style={webStyle.typographyTitleText}>
                                {configJSON.addNewMeterText}
                              </Typography>
                            </button>
                          </Box>
                        </>
                      )}
                    </FieldArray>
                    <Box sx={webStyle.fieldControlGroup}>
                      <button
                        style={webStyle.cancelButton}
                        data-test-id="cancelBtn"
                        type="button"
                        onClick={() => {}}
                      >
                        {configJSON.cancelBtnText}
                      </button>
                      <button
                        style={webStyle.submitButton}
                        data-test-id="submitBtn"
                        type="button"
                        onClick={() => {
                          this.goPropertieesPage();
                        }}
                      >
                        {configJSON.saveBtnText}
                      </button>
                    </Box>
                  </Box>
                  {console.log({ values })}
                </form>
              )}
            </Formik>
          </Box>
        </Box>
      </Box>
      //Merge Engine End DefaultContainer
      // Customizable Area Start
    );
  }
}
// Customizable Area Start
const webStyle = {
  addPropertiesContainer: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#F8FAFC",
  },
  addPropertiesBlock: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  addPropertyFormContainer: {
    margin: "auto",
    maxWidth: "600px",
    width: "80%",
  },
  formContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  formTitle: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    marginBottom: "10px",
  },
  formSubTitle: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#475467",
    marginBottom: "20px",
  },
  fieldControl: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    margin: "10px",
    position: "relative",
  },
  mainFieldControl: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    margin: "10px 0px",
    position: "relative",
  },
  arrayFieldControl: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    margin: "10px 0px",
    position: "relative",
  },
  fieldControlGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  fieldCheckboxControl: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    margin: "10px",
    position: "relative",
  },
  switchTitle: {
    alignItems: "center",
    display: "flex",
  },
  radioButtonTitleLabel: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#64748B",
    margin: "10px 0px",
    alignItems: "center",
    display: "flex",
  },
  inputLabel: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#334155",
    marginBottom: "5px",
    alignItems: "center",
    display: "flex",
  },
  radioButtonLabel: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#0F172A",
    alignItems: "center",
    display: "flex",
  },
  radioButtnBlock: {
    display: "flex",
    flexDirection: "row",
    margin: "10px 0px",
  },
  numberInputElemnt: {
    height: "56px",
    padding: "10px",
    border: "none",
    "-moz-appearance": "textfield",
    "& ::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& ::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    width: "34%",
    textAlign: "center" as const,
  },
  inputElemnt: {
    height: "56px",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
  },
  radioButtonElemnt: {
    height: "20px",
    width: "20px",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
  },
  errorIcon: {
    width: "24px",
    height: "24px",
    position: "absolute" as "absolute",
    top: 43,
    right: 16,
  },
  numberFieldControlIcon: {
    width: "24px",
    height: "24px",
  },
  numberFieldControlButton: {
    color: "#1A7BA41A",
    border: "none",
    width: "33%",
  },
  cancelButton: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    margin: "10px",
    height: "56px",
    borderRadius: "8px",
    border: "1px solid #1A7BA4",
    background: "#FFFFFF",
    color: "#1A7BA4",
    padding: "0px 20px",
  },
  submitButton: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    margin: "10px",
    height: "56px",
    borderRadius: "8px",
    border: "none",
    background: "#1A7BA4",
    color: "#FFFFFF",
    padding: "0px 20px",
  },
  meterToReadBlcok: {
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "row",
  },
  meterBlcok: {
    border: "1px solid #1A7BA44D",
    backgroundColor: "#1A7BA40F",
    padding: "16px",
    bordeRadius: "12px",
    margin: "20px 0px",
  },
  meterTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  meterTitleLabel: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "18px",
    fontWeight: 700,
    lineheight: "24px",
    color: "#0F172A",
  },
  addNewMeterButton: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    margin: "15px",
    height: "56px",
    borderRadius: "8px",
    border: "none",
    background: "#FFFFFF",
    color: "#1A7BA4",
    padding: "0px 20px",
    flexDirection: "row" as const,
    display: "flex",
    alignItems: "center",
    flex: 1,
    justifyContent: "center",
  },
  removeMeeterButton: {
    height: "36px",
    width: "46px",
    border: "none",
    backgroundColor: "inherit",
  },
  typographyTitleText: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "22px",
  },
  btnIcon: {
    width: "24px",
    height: "24px",
  },
  calendarIcon: {
    width: "24px",
    height: "24px",
    position: "absolute" as "absolute",
    top: 43,
    right: 16,
  },
};
// Customizable Area End

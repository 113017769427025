import React from "react";
// Customizable Area Start
import { Typography, Box } from "@mui/material";
import { searchIcon, noPropertieisIcon, downloadIcon } from "./assets";
// Customizable Area End

import ReportsPageController, {
  PaymentStatus,
  Props,
  configJSON,
} from "./ReportsPageController";
// Customizable Area Start

// Customizable Area End
export default class ReportsPage extends ReportsPageController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Box sx={webStyle.reportsContainer}>
        {this.state.reportList.length === 0 ? (
          <Box sx={webStyle.noReportBlock}>
            <Box sx={webStyle.noReportBlockTitle}>
              <img src={noPropertieisIcon} style={webStyle.mainImage} />
              <Typography style={webStyle.noReportTitle}>
                {configJSON.noReportTitleText}
              </Typography>
            </Box>
          </Box>
        ) : (
          <>
            <Box sx={webStyle.reportsTableHeader}>
              <Typography sx={webStyle.reportTitle}>
                {configJSON.reportText}
              </Typography>
              <Box sx={webStyle.searchContainer}>
                <Box sx={webStyle.fieldControl}>
                  <input
                    style={webStyle.inputElemnt}
                    data-test-id="search"
                    type={"text"}
                    placeholder={configJSON.searchPlaceholderText}
                    onChange={(event) => {
                      this.searchReports(event.target.value);
                    }}
                  />
                  <img src={searchIcon} alt="" style={webStyle.searchIcon} />
                </Box>
              </Box>
              <Typography sx={webStyle.reportCount}>
                {this.state.reportList.length + " results"}
              </Typography>
            </Box>
            <Box sx={webStyle.reportsTable}>
              <Box sx={webStyle.reportsTableHead}>
                <Typography style={webStyle.headerTitle}>
                  {configJSON.reportTitleText}
                </Typography>
                <Typography style={webStyle.headerTitle}>
                  {configJSON.propertyTitleText}
                </Typography>
                <Typography style={webStyle.headerTitle}>
                  {configJSON.generatedAtText}
                </Typography>
                <Typography style={webStyle.headerTitle}>
                  {configJSON.typeText}
                </Typography>
                <Typography style={webStyle.headerTitle}>
                  {configJSON.paymentStatusText}
                </Typography>
                <Typography style={webStyle.headerTitleAction}>
                  {configJSON.actionsText}
                </Typography>
              </Box>
              {this.state.reportList.map((report) => (
                <Box sx={webStyle.reportsTableBody} key={report.id}>
                  <Box sx={webStyle.reportItem}>{report.reportId}</Box>
                  <Box sx={webStyle.reportItem}>{report.propertysName}</Box>
                  <Box sx={webStyle.reportItem}>{report.generatedAt}</Box>
                  <Box sx={webStyle.reportItem}>{report.type}</Box>
                  <Box sx={webStyle.reportItem}>
                    {PaymentStatus.Paid === report.paymentStatus ? (
                      <Typography sx={webStyle.paidStyle}>
                        {" "}
                        {report.paymentStatus}{" "}
                      </Typography>
                    ) : (
                      ""
                    )}
                    {PaymentStatus.Pending === report.paymentStatus ? (
                      <Typography sx={webStyle.pendingStyle}>
                        {" "}
                        {report.paymentStatus}{" "}
                      </Typography>
                    ) : (
                      ""
                    )}
                    {PaymentStatus.NoInvoice === report.paymentStatus ? (
                      <Typography sx={webStyle.noInvoiceStyle}>
                        {" "}
                        {report.paymentStatus}{" "}
                      </Typography>
                    ) : (
                      ""
                    )}
                  </Box>
                  <Box sx={webStyle.reportItemAction}>
                    <button
                      style={webStyle.downloadButton}
                      onClick={() => {
                        this.downloadReport(report.id);
                      }}
                    >
                      <img src={downloadIcon} alt="" style={webStyle.btnIcon} />
                      <Typography style={webStyle.typographyText}>
                        {configJSON.reportButtonText}
                      </Typography>
                    </button>
                    <button
                      style={webStyle.downloadButton}
                      onClick={() => {
                        this.downloadInvoice(report.id);
                      }}
                    >
                      <img src={downloadIcon} alt="" style={webStyle.btnIcon} />
                      <Typography style={webStyle.typographyText}>
                        {configJSON.invoiceButtonText}
                      </Typography>
                    </button>
                  </Box>
                </Box>
              ))}
            </Box>
          </>
        )}
      </Box>
      //Merge Engine End DefaultContainer
      // Customizable Area Start
    );
  }
}
// Customizable Area Start
const webStyle = {
  reportsContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#F8FAFC",
    padding: "0px 30px",
  },
  reportsTableHeader: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "space-between",
    margin: "20px 0px",
  },
  searchContainer: {
    display: "flex",
    flexDirection: "row",
    width: "340px",
  },
  reportsTable: {
    border: "1px solid #CBD5E1",
    borderRadius: "12px",
  },
  reportsTableHead: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
  },
  reportsTableBody: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
    borderTop: "1px solid #CBD5E1",
  },
  fieldControl: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    marginTop: "10px",
    marginBottom: "10px",
    position: "relative",
  },
  inputElemnt: {
    height: "44px",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
  },
  searchIcon: {
    width: "24px",
    height: "24px",
    position: "absolute" as "absolute",
    top: 10,
    right: 16,
  },
  btnIcon: {
    width: "24px",
    height: "24px",
  },
  headerTitle: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "calc(20% - 50px)",
    margin: "12px 0px",
  },
  headerTitleAction: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "200px",
    margin: "12px 0px",
  },
  downloadButton: {
    backgroundColor: "#FFFFFF",
    color: "#1A7BA4",
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    padding: "6px 10px 6px 10px",
    borderRadius: "7px",
    border: "1px solid #1A7BA4",
    display: "flex",
    flexDirection: "row" as const,
    height: "36px",
    alignItems: "center",
  },
  reportItem: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "calc(20% - 50px)",
    margin: "13px 0px",
    alignItems: "center",
    display: "flex",
  },
  reportItemAction: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "200px",
    display: "flex",
    flexDirection: "row",
    margin: "13px 0px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  noReportBlock: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    maxWidth: "600px",
    width: "80%",
  },
  noReportBlockTitle: {
    margin: "80px 10px",
    textAlign: "center",
  },
  noReportTitle: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    marginBottom: "15px",
    marginTop: "50px",
  },
  mainImage: {
    width: "163px",
    height: "154px",
  },
  typographyText: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
  },
  paidStyle: {
    textTransform: "uppercase" as const,
    backgroundColor: "#D1FAE5",
    color: "#059669",
    padding: "2px 8px",
    borderRadius: "40px",
    border: "none",
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
  },
  pendingStyle: {
    textTransform: "uppercase" as const,
    backgroundColor: "#FEF3C7",
    color: "#D97706",
    padding: "2px 8px",
    borderRadius: "40px",
    border: "none",
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
  },
  noInvoiceStyle: {
    textTransform: "uppercase" as const,
    backgroundColor: "#F1F5F9",
    color: "#475569",
    padding: "2px 8px",
    borderRadius: "40px",
    border: "none",
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
  },
  reportTitle: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "30px",
    color: "#0F172A",
  },
  reportCount: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "16px",
    fonWeight: 500,
    lineHeight: "22px",
    color: "#334155",
  },
};
// Customizable Area End

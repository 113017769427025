Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.signUpApiEndPoint = "account_block/accounts";
exports.signUpApiMethod = "POST";
exports.signUpApiContentType = "application/json";
exports.logoText = "THE METER READING SERVICES";
exports.termsAndConditionsText = "Terms & Conditions";
exports.signUpText = "Sign up";
exports.createAnAccountText = "Create an account to start using our services.";


exports.zipCodeText = "Zip Code";
exports.nameText = "Name";
exports.emailText = "Email";
exports.passwordText = "Password";
exports.confirmPasswordText = "Confirm Password";

exports.zipCodePlaceholderText = "10001";
exports.namePlaceholderText = "Jhon Doe";
exports.emailPlaceholderText = "email@example.com";
exports.passwordPlaceholderText = "••••••••••";
exports.confirmPasswordPlaceholderText = "••••••••••";

exports.alLeastSevenLlettersText = "At least 7 characters";
exports.agreeoText = "I agree to the ";
exports.alreadeyHaveAnAccountText = "Already have an account?";
exports.logInText = "Log In";
// Customizable Area End
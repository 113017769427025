import React from "react";
// Customizable Area Start
import { Typography, Box, Tooltip, Modal } from "@mui/material";
import * as Yup from "yup";

import { Formik } from "formik";
import {
  banner,
  passwordHiddenIcon,
  navLogo,
  errorIcon,
  closeIcon,
  successIcon,
} from "./assets";
import { configJSON } from "./SignUpPageController";
// Customizable Area End

import SignUpPageController, { Props } from "./SignUpPageController";
// Customizable Area Start

// Customizable Area End
export default class SignUpPage extends SignUpPageController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
  baseURL = require("../../../framework/src/config.js").baseURL;
  questionSchema = () => {
    return Yup.object().shape({
      zip_code: Yup.string()
        .required("Please enter your zip")
        .matches(/^\d{5,10}(?:[-\s]\d{4})?$/, "Zip code is invalid") // need to check again
        .test(
          "length",
          "Zip code needs to be between 5 and 10 digits",
          (val: any) => val?.length >= 5 && val?.length <= 10
        )
        .typeError("Please enter a valid zip code"),
      email: Yup.string()
        .email("Invalid email format")
        .required("Mail is required"),
      full_name: Yup.string().required("Name is required"),
      password: Yup.string()
        .min(8, "Must be at least 8 characters")
        .required("Required"),
      password_confirmation: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Passwords must match"
      ),
      term_agree: Yup.boolean()
        .required("Required")
        .oneOf([true], "You must accept the terms"),
    });
  };
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Box sx={webStyle.signUpContainer}>
        <Modal
          open={this.state.showModal}
          onClose={() => {
            this.setModalShow(false);
          }}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Box sx={webStyle.modalBox}>
            <Box sx={webStyle.modalTitleBlock}>
              <Typography style={webStyle.modalTitle}>
                {configJSON.termsAndConditionsText}
              </Typography>
              <button
                style={webStyle.modalCloseButton}
                onClick={() => this.setModalShow(false)}
              >
                <img src={closeIcon} alt="Logo" style={{ height: "24px" }} />
              </button>
            </Box>
            <Box
              component="div"
              sx={webStyle.modalScrollBox}
              dangerouslySetInnerHTML={{
                __html:
                  this.state.termsAndConditionPageContentData?.description,
              }}
            />
          </Box>
        </Modal>

        <Box sx={webStyle.leftBlock}>
          <Box sx={webStyle.logoContainer}>
            <img src={navLogo} alt="Logo" style={{ height: "40px" }} />
            <Typography sx={webStyle.logoText}>
              {configJSON.logoText}
            </Typography>
          </Box>
          <Box sx={webStyle.signUpFormContainer}>
            <Box>
              <Typography style={webStyle.formTitle}>
                {configJSON.signUpText}
              </Typography>
              <Typography style={webStyle.formSubTitle}>
                {configJSON.createAnAccountText}
              </Typography>
            </Box>
            <Formik
              initialValues={{
                zip_code: "",
                full_name: "",
                email: "",
                password: "",
                password_confirmation: "",
                term_agree: false,
              }}
              validateOnMount={true}
              validateOnChange={true}
              validationSchema={this.questionSchema}
              onSubmit={() => {
                this.setValidate(true);
                this.doSignUp();
              }}
              data-test-id="SignUpForm"
            >
              {({ errors, values, setFieldValue, handleSubmit }) => (
                <form>
                  <Box sx={webStyle.formContainer}>
                    <Box sx={webStyle.fieldControl}>
                      <label style={webStyle.inputLabel} htmlFor={"zip_code"}>
                        {configJSON.zipCodeText}
                      </label>
                      <input
                        style={webStyle.inputElemnt}
                        data-test-id="zip_code"
                        type="text"
                        placeholder={configJSON.zipCodePlaceholderText}
                        onChange={(event) => {
                          setFieldValue("zip_code", event.target.value);
                        }}
                      />
                      {(this.state.validate && errors.zip_code) || this.state.zipCodeTextError && (
                        <Tooltip
                          open={Boolean(errors.zip_code || this.state.zipCodeTextError)}
                          title={errors.zip_code || this.state.zipCodeTextError}
                        >
                          <img
                            src={errorIcon}
                            alt=""
                            style={webStyle.errorIcon}
                          />
                        </Tooltip>
                      )}
                    </Box>
                    <Box sx={webStyle.fieldControl}>
                      <label style={webStyle.inputLabel} htmlFor={"full_name"}>
                        {configJSON.nameText}
                      </label>
                      <input
                        style={webStyle.inputElemnt}
                        data-test-id="full_name"
                        type="text"
                        placeholder={configJSON.namePlaceholderText}
                        onChange={(event) => {
                          setFieldValue("full_name", event.target.value);
                        }}
                      />
                      {this.state.validate && errors.full_name && (
                        <Tooltip
                          open={Boolean(errors.full_name)}
                          title={errors.full_name}
                        >
                          <img
                            src={errorIcon}
                            alt=""
                            style={webStyle.errorIcon}
                          />
                        </Tooltip>
                      )}
                    </Box>
                    <Box sx={webStyle.fieldControl}>
                      <label style={webStyle.inputLabel} htmlFor={"email"}>
                        {configJSON.emailText}
                      </label>
                      <input
                        style={webStyle.inputElemnt}
                        data-test-id="email"
                        type="email"
                        placeholder={configJSON.emailPlaceholderText}
                        onChange={(event) => {
                          setFieldValue("email", event.target.value);
                        }}
                      />
                      {(this.state.validate && errors.email) || this.state.emailTextError && (
                        <Tooltip
                          open={Boolean(errors.email || this.state.emailTextError)}
                          title={errors.email || this.state.emailTextError}
                        >
                          <img
                            src={errorIcon}
                            alt=""
                            style={webStyle.errorIcon}
                          />
                        </Tooltip>
                      )}
                    </Box>
                    <Box sx={webStyle.fieldControl}>
                      <label style={webStyle.inputLabel} htmlFor={"password"}>
                        {configJSON.passwordText}
                      </label>
                      <input
                        style={webStyle.inputElemnt}
                        data-test-id="password"
                        type={this.state.showPassowrd ? "text" : "password"}
                        placeholder={configJSON.passwordPlaceholderText}
                        onChange={(event) => {
                          setFieldValue("password", event.target.value);
                        }}
                      />
                      {this.state.validate && errors.password ? (
                        <Tooltip
                          open={Boolean(errors.password)}
                          title={errors.password}
                        >
                          <img
                            src={errorIcon}
                            alt=""
                            style={webStyle.errorIcon}
                          />
                        </Tooltip>
                      ) : (
                        <img
                          src={passwordHiddenIcon}
                          alt=""
                          style={webStyle.passwordHiddenIcon}
                          onClick={() =>
                            this.setShowPassword(!this.state.showPassowrd)
                          }
                        />
                      )}

                      <Typography style={webStyle.passwordHelp}>
                        {configJSON.alLeastSevenLlettersText}
                      </Typography>
                    </Box>
                    <Box sx={webStyle.fieldControl}>
                      <label
                        style={webStyle.inputLabel}
                        htmlFor={"password_confirmation"}
                      >
                        {configJSON.confirmPasswordText}
                      </label>
                      <input
                        style={webStyle.inputElemnt}
                        data-test-id="password_confirmation"
                        type={
                          this.state.showConfirmPassword ? "text" : "password"
                        }
                        placeholder={configJSON.confirmPasswordPlaceholderText}
                        onChange={(event) => {
                          setFieldValue("password_confirmation", event.target.value);
                        }}
                      />
                      {this.state.validate && errors.password_confirmation ? (
                        <Tooltip
                          open={Boolean(errors.password_confirmation)}
                          title={errors.password_confirmation}
                        >
                          <img
                            src={errorIcon}
                            alt=""
                            style={webStyle.errorIcon}
                          />
                        </Tooltip>
                      ) : (
                        <img
                          src={passwordHiddenIcon}
                          alt=""
                          style={webStyle.passwordHiddenIcon}
                          onClick={() =>
                            this.setShowConfirmPassword(
                              !this.state.showConfirmPassword
                            )
                          }
                        />
                      )}
                    </Box>
                    <Box sx={webStyle.fieldCheckboxControl}>
                      <Box
                        sx={
                          values?.term_agree
                            ? webStyle.checkboxChecked
                            : webStyle.checkboxUnchecked
                        }
                        onClick={() => {
                          setFieldValue("term_agree", !values.term_agree);
                        }}
                      >
                        <img src={successIcon} style={webStyle.successIcon} />
                      </Box>
                      <input
                        style={webStyle.checkboxElemnt}
                        data-test-id="term_agree"
                        type="checkbox"
                        onChange={(event) => {
                          setFieldValue("term_agree", event.target.checked);
                        }}
                      />
                      <label
                        style={webStyle.checkboxLabel}
                        htmlFor={"term_agree"}
                      >
                        {configJSON.agreeoText}{" "}
                        <button
                          type="button"
                          style={webStyle.termAndConditionsButton}
                          onClick={() => this.setModalShow(true)}
                        >
                          {configJSON.termsAndConditionsText}
                        </button>
                      </label>
                      {this.state.validate && errors.term_agree && (
                        <Tooltip
                          open={Boolean(errors.term_agree)}
                          title={errors.term_agree}
                        >
                          <img
                            src={errorIcon}
                            alt=""
                            style={webStyle.errorAcceptIcon}
                          />
                        </Tooltip>
                      )}
                    </Box>
                    <button
                      style={webStyle.submitButton}
                      data-test-id="submitBtn"
                      type="button"
                      onClick={() => {
                        this.setFormDate(values);
                        this.setValidate(true);
                        if (Object.keys(errors).length == 0) {
                          handleSubmit();
                        }
                      }}
                    >
                      {configJSON.signUpText}
                    </button>
                  </Box>
                </form>
              )}
            </Formik>
            <Box sx={webStyle.loginRedirection}>
              <Typography style={webStyle.loginRedirectionLabel}>
                {configJSON.alreadeyHaveAnAccountText}
              </Typography>
              <button
                style={webStyle.loginRedirectionButton}
                onClick={() => this.goToLogIn()}
              >
                {configJSON.logInText}
              </button>
            </Box>
          </Box>
        </Box>
        <Box sx={webStyle.rightBlock}>
          <Box sx={webStyle.rightBlockBackground} />
          {/* Bkock right */}
        </Box>
      </Box>

      //Merge Engine End DefaultContainer
      // Customizable Area Start
    );
  }
}
// Customizable Area Start
const webStyle = {
  signUpContainer: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#F8FAFC",
  },
  rightBlock: {
    flex: 1,
    display: "flex",
  },
  rightBlockBackground: {
    flex: 1,
    display: "flex",
    margin: "20px",
    backgroundImage: `url(${banner})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPositionX: "50%",
    borderRadius: "24px",
    height: "880px",
  },
  leftBlock: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  logoContainer: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    margin: "40px auto",
  },
  logoText: {
    fontFamily: "Oswald, sans-serif",
    fontWeight: 600,
    fontSize: "26px",
    lineHeight: "24px",
  },
  signUpFormContainer: {
    margin: "auto",
    maxWidth: "600px",
    width: "80%",
  },
  formContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  formTitle: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    marginBottom: "15px",
  },
  formSubTitle: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#475467",
    marginBottom: "20px",
  },
  fieldControl: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    marginTop: "10px",
    marginBottom: "10px",
    position: "relative",
  },
  fieldCheckboxControl: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    marginTop: "10px",
    marginBottom: "10px",
    position: "relative",
  },
  inputLabel: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#334155",
    marginBottom: "5px",
    alignItems: "center",
    display: "flex",
  },
  inputElemnt: {
    height: "56px",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
  },
  passwordHelp: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#94A3B8",
    marginTop: "5px",
    marginBottom: "5px",
  },
  passwordHiddenIcon: {
    width: "24px",
    height: "24px",
    position: "absolute" as "absolute",
    top: 43,
    right: 16,
  },
  errorIcon: {
    width: "24px",
    height: "24px",
    position: "absolute" as "absolute",
    top: 43,
    right: 16,
  },
  errorAcceptIcon: {
    width: "24px",
    height: "24px",
    position: "absolute" as "absolute",
    top: 4,
    right: 16,
  },
  checkboxLabel: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#0F172A",
    alignItems: "center",
    display: "flex",
  },
  termAndConditionsButton: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#0F172A",
    border: "none",
    backgroundColor: "#F8FAFC",
    textDecoration: "underline",
  },
  checkboxUnchecked: {
    border: "1px solid #64748B",
    width: "20px",
    height: "20px",
    backgroundColor: "#FFFFFF",
    borderRadius: "8px",
    margin: "2px",
  },
  checkboxChecked: {
    border: "1px solid #1A7BA4",
    width: "20px",
    height: "20px",
    backgroundColor: "#1A7BA4",
    borderRadius: "8px",
    margin: "2px",
  },
  successIcon: {
    width: "14px",
    height: "14px",
    margin: "3px",
  },
  checkboxElemnt: {
    appearance: "none" as const,
  },
  submitButton: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    width: "100%",
    height: "56px",
    borderRadius: "8px",
    border: "none",
    background: "#1A7BA4",
    color: "#FFFFFF",
  },
  loginRedirection: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    margin: "20px auto",
    width: "max-content",
  },
  loginRedirectionLabel: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "22px",
    marginRight: "8px",
    color: "#0F172A",
    alignItems: "center",
    display: "flex",
  },
  loginRedirectionButton: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#1A7BA4",
    border: "none",
    backgroundColor: "#F8FAFC",
  },
  modalBox: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40vw",
    height: "80vh",
    backgroundColor: "#FFFFFF",
    border: "none",
    borderRadius: "18px",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
  },
  modalScrollBox: {
    mb: 2,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    overflowY: "scroll",
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "32px",
    textAlign: "left",
    flex: 1,
    color: "0F172A",
    marginTop: "20px",
  },
  modalTitleBlock: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: "20px",
    borderBottom: "1px solid #CBD5E1",
  },
  modalTitle: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    color: "#000000",
  },
  modalCloseButton: {
    color: "#1A7BA4",
    border: "none",
    backgroundColor: "#F8FAFC",
  },
};
// Customizable Area End

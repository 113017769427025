import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface Meter {
  id: string;
  meterNumber: string;
  type: string;
  lastRiding: string;
  dateLastRiding: string;
}

interface S {
  meterList: Meter[]
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class MeterPageController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [];

    this.state = {
      meterList: [
        {
          id: "1",
          meterNumber: "HJG8762",
          type: "Water",
          lastRiding: "28,197",
          dateLastRiding: "15/12/2024",
        },
        {
          id: "2",
          meterNumber: "ZJ19783",
          type: "Electrical",
          lastRiding: "13,187,134",
          dateLastRiding: "15/12/2024",
        },
        {
          id: "3",
          meterNumber: "13UDS23",
          type: "Water",
          lastRiding: "1,198",
          dateLastRiding: "15/12/2024",
        },
      ]
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  searchProperties = (search: string) => {

  }

  addMeter = () => {

  }

  editMeter = (id: string) => {
    
  }

  deleteMeter = (id: string) => {
    
  }

  goBack = () => {
    this.props.navigation.navigate("TenantPage");
  }

  // Customizable Area Start
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  // Customizable Area End
}

import React, { Component } from 'react';
import { Typography, Box } from '@mui/material';
import { navLogo } from "../../blocks/landingpage/src/assets";
export const configJSON = require("../../blocks/landingpage/src/config");

export const notificatiionIcon = require("./notificatiion.svg");

export default class AdminHeader extends Component {

  render() {
    return (
      <Box sx={styles.headerConatiner}>
        <Box sx={styles.headerWrapper}>
          <Box sx={styles.logoContainer}>
            <img src={navLogo} alt="Logo" style={{ height: '25px' }} />
            <Typography sx={styles.logoText}>
              {configJSON.logoText}
            </Typography>
          </Box>
          <Box sx={styles.accountContaineer}>
            <Box sx={styles.notificationBlock}>
              <img src={notificatiionIcon} alt="Logo" style={{ height: '32px' }} />
              {/* <Typography sx={styles.notificationCount}>2</Typography> */}
            </Box>
            <Box sx={styles.accountBlock}>
              <Typography sx={styles.accounInitials}>JS</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
}
const styles = {
  headerConatiner: {
    boxShadow: "0px 2px 8px 0px #00000014",
    height: "64px",
  },
  headerWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",   
    width: "100%",
    position: "relative",
    top: 0
  },
  accountContaineer: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    // flex: 1,
    padding: "0px 40px",

  },
  notificationBlock: {
    position: "relative",
    height: "32px",
    width: "32px",
    margin: "16px",
  },
  notificationCount: {
    position: "absolute",
    top: "0px",
    left: "0px",
  },
  accountBlock: {
    width: "32px",
    height: "32px",
    // height: "64px",
    // width: "64px",
    margin: "16px",

    backgroundColor: "#F0E5FF",
    borderRadius: "25px",
    border: "none",
  },
  accounInitials: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: "32px",
    textAlign: "center",
    color: "#94A3B8",
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    // flex: 1,
    padding: "0px 40px",
  },
  logoText: {
    fontFamily: 'Oswald, sans-serif',

    // font-family: Oswald;
    fontsize: "16.25px",
    fontHeight: 600,
    lineWeight: "15px",
    // text-align: left;

    // fontWeight: 600,
    // fontSize: '39px',
    // lineHeight: '36px',
  },
};

import React from "react";
// Customizable Area Start
import { Typography, Box, Tooltip } from "@mui/material";
import * as Yup from "yup";
import Switch, { SwitchProps } from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Formik } from "formik";
import { errorIcon, addIcon, removeIcon } from "./assets";
// Customizable Area End

import AddPropertiesPageController, { Props, configJSON } from "./AddPropertiesPageController";
// Customizable Area Start
const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#1A7BA4',
        opacity: 1,
        border: 0,
        ...theme.applyStyles('dark', {
          backgroundColor: '#2ECA45',
        }),
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.grey[100],
      ...theme.applyStyles('dark', {
        color: theme.palette.grey[600],
      }),
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7,
      ...theme.applyStyles('dark', {
        opacity: 0.3,
      }),
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#E9E9EA',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
    ...theme.applyStyles('dark', {
      backgroundColor: '#39393D',
    }),
  },
}));

// Customizable Area End
export default class AddPropertiesPage extends AddPropertiesPageController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
  questionSchema = () => {
    return Yup.object().shape({
      propertyName: Yup.string().required("Property Name is required"),
      serviceAddress: Yup.string().required("Service Address is required"),
      meterLocation: Yup.string().required("Meter Location is required"),
      buildingManager: Yup.string().required("Building Manager is required"),
      phone: Yup.string().required("Phone Number is required"),
      email: Yup.string().email("Invalid email format").required("Mail is required"),
      meterToRead: Yup.number().required("Phone Number is required"),
      readingPeriod: Yup.object().shape({
        value: Yup.string().required("Required"),
        label: Yup.string().required("Required"),
      }),
      reportTo: Yup.object().shape({
        value: Yup.string().required("Required"),
        label: Yup.string().required("Required"),
      }),
      startReadingFrom: Yup.object().shape({
        value: Yup.string().required("Required"),
        label: Yup.string().required("Required"),
      }),
      getInvoice: Yup.boolean()
        .required("Required"),
      addServiceCharge: Yup.boolean()
        .required("Required"),
    });
  };
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Box sx={webStyle.addPropertiesContainer}>        
        <Box sx={webStyle.addPropertiesBlock}>
          <Box sx={webStyle.addPropertyFormContainer}>
            <Box sx={webStyle.addPropertiesBlockTitle}>
              <Typography style={webStyle.formTitle}>{configJSON.addPropertyText}</Typography>
              <Typography style={webStyle.formSubTitle}>
                {configJSON.enterPropertiydetailsText}
              </Typography>
            </Box>
            <Formik
              initialValues={{
                propertyName: "",
                serviceAddress: "",
                meterLocation: "",
                buildingManager: "",
                phone: "",
                email: "",
                meterToRead: "0",
                readingPeriod: "",
                startReadingFrom: "",
                reportTo: "",
                getInvoice: true,
                addServiceCharge: true,
              }}
              validateOnMount={true}
              validateOnChange={true}
              validationSchema={this.questionSchema}
              onSubmit={() => {
                this.setValidate(true);
              }}
              data-test-id="addPropertyForm"
            >
              {({ errors, values, setFieldValue }) => (
                <form>
                  <Box sx={webStyle.formContainer}>
                    <Box sx={webStyle.fieldControl}>
                      <label style={webStyle.inputLabel} htmlFor={"propertyName"}>
                        {configJSON.propertyNameText}
                      </label>
                      <input
                        style={webStyle.inputElemnt}
                        data-test-id="propertyName"
                        type="text"
                      />
                      {this.state.validate && errors.propertyName && (
                        <Tooltip
                          open={Boolean(errors.propertyName)}
                          title={errors.propertyName}
                        >
                          <img
                            src={errorIcon}
                            alt=""
                            style={webStyle.errorIcon}
                          />
                        </Tooltip>
                      )}
                    </Box>
                    <Box sx={webStyle.fieldControl}>
                      <label style={webStyle.inputLabel} htmlFor={"serviceAddress"}>
                        {configJSON.serviceAddressText}
                      </label>
                      <input
                        style={webStyle.inputElemnt}
                        data-test-id="serviceAddress"
                        type="email"
                      />
                      {this.state.validate && errors.serviceAddress && (
                        <Tooltip
                          open={Boolean(errors.serviceAddress)}
                          title={errors.serviceAddress}
                        >
                          <img
                            src={errorIcon}
                            alt=""
                            style={webStyle.errorIcon}
                          />
                        </Tooltip>
                      )}
                    </Box>
                    <Box sx={webStyle.fieldControl}>
                      <label style={webStyle.inputLabel} htmlFor={"meterLocation"}>
                        {configJSON.meterLocationText}

                      </label>
                      <input
                        style={webStyle.inputElemnt}
                        data-test-id="meterLocation"
                        type="text"
                      />
                      {this.state.validate && errors.meterLocation && (
                        <Tooltip
                          open={Boolean(errors.meterLocation)}
                          title={errors.meterLocation}
                        >
                          <img
                            src={errorIcon}
                            alt=""
                            style={webStyle.errorIcon}
                          />
                        </Tooltip>
                      )}
                    </Box>
                    <Box sx={webStyle.fieldControl}>
                      <label style={webStyle.inputLabel} htmlFor={"buildingManager"}>
                        {configJSON.buildingManagerText}
                      </label>
                      <input
                        style={webStyle.inputElemnt}
                        data-test-id="buildingManager"
                        type={"text"}
                      />
                      {(this.state.validate && errors.buildingManager) && (
                        <Tooltip
                          open={Boolean(errors.buildingManager)}
                          title={errors.buildingManager}
                        >
                          <img
                            src={errorIcon}
                            alt=""
                            style={webStyle.errorIcon}
                          />
                        </Tooltip>
                      )}
                    </Box>
                    <Box sx={webStyle.fieldControlGroup}>
                      <Box sx={webStyle.fieldControl}>
                        <label
                          style={webStyle.inputLabel}
                          htmlFor={"phone"}
                        >
                          {configJSON.phoneNumberText}
                        </label>
                        <input
                          style={webStyle.inputElemnt}
                          data-test-id="phone"
                          type={"text"}
                        />
                        {(this.state.validate && errors.phone) && (
                          <Tooltip
                            open={Boolean(errors.phone)}
                            title={errors.phone}
                          >
                            <img
                              src={errorIcon}
                              alt=""
                              style={webStyle.errorIcon}
                            />
                          </Tooltip>
                        )}
                      </Box>
                      <Box sx={webStyle.fieldControl}>
                        <label
                          style={webStyle.inputLabel}
                          htmlFor={"email"}
                        >
                          {configJSON.emailText}
                        </label>
                        <input
                          style={webStyle.inputElemnt}
                          data-test-id="email"
                          type={"email"}
                        />
                        {(this.state.validate && errors.email) && (
                          <Tooltip
                            open={Boolean(errors.email)}
                            title={errors.email}
                          >
                            <img
                              src={errorIcon}
                              alt=""
                              style={webStyle.errorIcon}
                            />
                          </Tooltip>
                        )}
                      </Box>
                    </Box>
                    <Box sx={webStyle.fieldControlGroup}>
                      <Box sx={webStyle.fieldControl}>
                        <label
                          style={webStyle.inputLabel}
                          htmlFor={"meterToRead"}
                        >
                          {configJSON.meterOfReadText}
                        </label>
                        <Box sx={webStyle.meterToReadBlcok}>
                          <button 
                            style={webStyle.numberFieldControlButton} 
                            type="button"
                            onClick={() => { 
                              setFieldValue("meterToRead", (Number(values.meterToRead) - 1).toString());
                            }}
                          >
                            <img src={removeIcon} style={webStyle.numberFieldControlIcon} />
                          </button>
                          <input
                            style={webStyle.numberInputElemnt}
                            data-test-id="meterToRead"
                            type="text"
                            min={0}
                            value={values?.meterToRead || "0"}
                          />
                          <button 
                            style={webStyle.numberFieldControlButton} 
                            type="button"
                            onClick={() => { 
                              setFieldValue("meterToRead", (Number(values.meterToRead) + 1).toString());
                            }}
                          >
                            <img src={addIcon} style={webStyle.numberFieldControlIcon} />
                          </button>
                        </Box>
                      </Box>
                      <Box sx={webStyle.fieldControl}>
                        <label
                          style={webStyle.inputLabel}
                          htmlFor={"readingPeriod"}
                        >
                          {configJSON.readingPerionText}
                        </label>
                        <Select
                          value={0}
                          displayEmpty
                          inputProps={{ 'aria-label': 'Without label' }}
                          data-test-id="readingPeriod"
                        >
                          <MenuItem value={0}>Monthly</MenuItem>
                        </Select>
                      </Box>
                      <Box sx={webStyle.fieldControl}>
                        <label
                          style={webStyle.inputLabel}
                          htmlFor={"startReadingFrom"}
                        >
                          {configJSON.startReadingFronText}
                        </label>
                        <Select
                          value={1}
                          displayEmpty
                          inputProps={{ 'aria-label': 'Without label' }}
                          data-test-id="startReadingFrom"
                        >
                          <MenuItem value={1}>January</MenuItem>
                        </Select>
                      </Box>
                    </Box>
                    <Box sx={webStyle.fieldControl}>
                        <label
                          style={webStyle.inputLabel}
                          htmlFor={"reportTo"}
                        >
                          {configJSON.receiveReportText}
                        </label>
                        <Select
                          value={0}
                          displayEmpty
                          inputProps={{ 'aria-label': 'Without label' }}
                          data-test-id="reportTo"
                        >
                          <MenuItem value={0}>{configJSON.emailText}</MenuItem>
                        </Select>
                    </Box>
                    <Box sx={webStyle.fieldCheckboxControl}>
                      <Typography style={webStyle.switchTitle}>{configJSON.getInvoiceText}</Typography>
                      <IOSSwitch 
                        sx={{ m: 1 }} 
                        defaultChecked 
                        data-test-id="getInvoice"
                      />
                    </Box>
                    <Box sx={webStyle.fieldCheckboxControl}>
                      <Typography style={webStyle.switchTitle}>{configJSON.addServiceText}</Typography>
                      <IOSSwitch 
                        sx={{ m: 1 }} 
                        defaultChecked 
                        data-test-id="addServiceCharge"
                      />
                    </Box>
                    <Box sx={webStyle.fieldControlGroup}>
                      <button
                        style={webStyle.cancelButton}
                        data-test-id="cancelBtn"
                        type="button"
                        onClick={() => {}}
                      >
                        {configJSON.cancelText}
                      </button>
                      <button
                        style={webStyle.submitButton}
                        data-test-id="submitBtn"
                        type="button"
                        onClick={() => { this.goPropertieesPage(); }}
                      >
                        {configJSON.saveText}
                      </button>
                    </Box>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Box>
      </Box>
      //Merge Engine End DefaultContainer
      // Customizable Area Start
    );
  }
}
// Customizable Area Start
const webStyle = {
  addPropertiesContainer: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#F8FAFC",
  },
  addPropertiesBlock: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  addPropertiesBlockTitle: {
    margin: "10px",
  },
  addPropertyFormContainer: {
    margin: "auto",
    maxWidth: "700px",
    width: "80%",
  },
  formContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  formTitle: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    marginBottom: "6px",
  },
  formSubTitle: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#475467",
    marginBottom: "20px",
  },
  fieldControl: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    margin: "10px",
    position: "relative",
  },
  fieldControlGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  fieldCheckboxControl: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    margin: "10px",
    position: "relative",
  },
  switchTitle: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#334155",
    alignItems: "center",
    display: "flex",
  },
  inputLabel: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#334155",
    marginBottom: "5px",
    alignItems: "center",
    display: "flex",
  },
  numberInputElemnt: {
    height: "56px",
    padding: "10px",
    border: "none",
    '-moz-appearance': 'textfield',
    '& ::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
    },
    '& ::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
    },
    width: "34%",
    textAlign: "center" as const,
  },
  inputElemnt: {
    height: "56px",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
  },
  errorIcon: {
    width: "24px",
    height: "24px",
    position: "absolute" as "absolute",
    top: 43,
    right: 16,
  },
  numberFieldControlIcon: {
    width: "24px",
    height: "24px",
  },
  numberFieldControlButton: {
    color: "#1A7BA41A",
    border: "none",
    width: "33%",
  },
  cancelButton: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    margin: "10px",
    height: "44px",
    borderRadius: "8px",
    border: "1px solid #1A7BA4",
    background: "#FFFFFF",
    color: "#1A7BA4",
    padding: "0px 20px",
  },
  submitButton: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    margin: "10px",
    height: "44px",
    borderRadius: "8px",
    border: "none",
    background: "#1A7BA4",
    color: "#FFFFFF",
    padding: "0px 20px",
  },
  meterToReadBlcok: {
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "row",
  },
};
// Customizable Area End

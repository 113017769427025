Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.logInApiEndPoint = "bx_block_login/logins";
exports.logInApiMethod = "POST";
exports.logInApiContentType = "application/json";


exports.logoText = "THE METER READING SERVICES";
exports.signUpText = "Sign Up";
exports.donthaveAnAccountText = "Don’t have an account?";
exports.logInText = "Log in";
exports.forgotPasswordText = "Forgot password?";
exports.emailText = "Email";
exports.emailPlaceholderText = "email@example.com";
exports.passwordText = "Password";
exports.passwordPlaceholderText = "••••••••••";
exports.welcomeBackText = "Welcome back! Please enter your details.";

// Customizable Area End
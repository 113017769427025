import React from "react";
// Customizable Area Start
import { Typography, Box } from "@mui/material";
import { searchIcon, addIcon, editIcon, deleteIcon, leftArrowIcon } from "./assets";
// Customizable Area End

import TenantPageController, { Props, configJSON } from "./TenantPageController";
// Customizable Area Start

// Customizable Area End
export default class TenantPage extends TenantPageController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Box sx={webStyle.propertiesContainer}>
        <Box sx={webStyle.tenantTitleContainer}>
          <Box sx={webStyle.tenantActionContainer}>
            <button style={webStyle.backButton} onClick={this.goBack}>
              <img
                src={leftArrowIcon}
                alt=""
                style={webStyle.btnIcon}
              />
              <Typography style={webStyle.typographyTitleText}>{configJSON.backtoPropertiesText}</Typography>
            </button>
            <Typography style={webStyle.tenantTitle}>Abstergo Ltd.</Typography>
            <Typography style={webStyle.tenantSubTitle}>New Hyde park, NY 11040</Typography>
          </Box>
        </Box>
        <Box sx={webStyle.propertiesTableHeader}>
          <Box sx={webStyle.searchContainer}>
            <Box sx={webStyle.fieldControl}>
              <input
                style={webStyle.inputElemnt}
                data-test-id="search"
                type={"text"}
                placeholder={configJSON.serachPlaceholderText}
                onChange={(event) => { this.searchProperties(event.target.value) }}
              />
              <img
                src={searchIcon}
                alt=""
                style={webStyle.searchIcon}
                />
            </Box>
          </Box>
          <Box sx={webStyle.actionContainer}>
            <button style={webStyle.addPropertiesButton} onClick={() => { this.addMeter("0") }}>
              <img
                src={addIcon}
                alt=""
                style={webStyle.btnIcon}
              />
              <Typography style={webStyle.typographyTitleText}>{configJSON.addTeenantText}</Typography>
            </button>
          </Box>
        </Box>
        <Box sx={webStyle.propertisTable}>
          <Box sx={webStyle.propertisTableHead}>
            <Typography style={webStyle.headerTitle}>{configJSON.tenantNameText}</Typography>
            <Typography style={webStyle.headerTitle}>{configJSON.numberOfMetersText}</Typography>
            <Typography style={webStyle.headerTitleAction}>{configJSON.actionsText}</Typography>
          </Box>
          {this.state.tenantList.map(tenant => (
            <Box sx={webStyle.propertisTableBody} key={tenant.id}>
              <Box sx={webStyle.propertyItem}>{tenant.tenantName}</Box>
              <Box sx={webStyle.propertyItem}>{tenant.countMeter}</Box>
              <Box sx={webStyle.propertyItemAction}>
                <button style={webStyle.addTenantButton} onClick={() => this.addMeter(tenant.id) }>
                  <img
                    src={addIcon}
                    alt=""
                    style={webStyle.btnIcon}
                  />
                  <Typography style={webStyle.typographyText}>{configJSON.addMeterText}</Typography>
                </button>
                <button style={webStyle.editButton} onClick={() => this.editTenant(tenant.id) }>
                  <img
                    src={editIcon}
                    alt=""
                    style={webStyle.btnIcon}
                  />
                  <Typography style={webStyle.typographyText}>{configJSON.editText}</Typography>
                </button>
                <button style={webStyle.deleteButton} onClick={() => this.deleteTenant(tenant.id) }>
                  <img
                    src={deleteIcon}
                    alt=""
                    style={webStyle.btnIcon}
                  />
                  <Typography style={webStyle.typographyText}>{configJSON.deleteText}</Typography>
                </button>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      //Merge Engine End DefaultContainer
      // Customizable Area Start
    );
  }
}
// Customizable Area Start
const webStyle = {
  propertiesContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#F8FAFC",
    padding: "0px 30px",
  },
  tenantTitleContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    margin: "20px 0px",
    justifyContent: "space-between",
  },
  propertiesTableHeader: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    margin: "20px 0px",
  },
  searchContainer: {
    width: "340px",
    display: "flex",
    flexDirection: "row",
  },
  actionContainer: {
    display: "flex",
    flexDirection: "row",
  },
  tenantActionContainer: {
    display: "flex",
    flexDirection: "column", 
  },
  propertisTable: {
    border: "1px solid #CBD5E1",
    borderRadius: "12px",
  },
  propertisTableHead: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
  },
  propertisTableBody: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
    borderTop: "1px solid #CBD5E1",
  },
  fieldControl: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    marginTop: "10px",
    marginBottom: "10px",
    position: "relative",
  },
  inputElemnt: {
    height: "44px",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
  },
  searchIcon: {
    width: "24px",
    height: "24px",
    position: "absolute" as "absolute",
    top: 10,
    right: 16,
  },
  btnIcon: {
    width: "24px",
    height: "24px",
  },
  backButton: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#1A7BA4",
    border: "none",
    backgroundColor: "#F8FAFC",
    display: "flex",
    flexDirection: "row" as const,
    height: "44px",
    alignItems: "center",
    margin: "10px 0px",
    padding: "6px 0px",
  },
  addPropertiesButton: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#FFFFFF",
    border: "none",
    borderRadius: "7px",
    backgroundColor: "#1A7BA4",
    display: "flex",
    flexDirection: "row" as const,
    height: "44px",
    alignItems: "center",
    margin: "10px 0px",
    padding: "6px 12px",
  },
  headerTitle: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "calc(50% - 150px)",
    margin: "12px 0px",
  },
  headerTitleAction: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    // flex: 1,
    width: "300px",
    margin: "12px 0px",
  },
  addTenantButton: {
    backgroundColor: "#1A7BA4",
    color: "#FFFFFF",
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    padding: "6px 10px 6px 10px",
    borderRadius: "7px",
    border: "none",
    display: "flex",
    flexDirection: "row" as const,
    height: "36px",
    alignItems: "center",
  },
  editButton: {
    backgroundColor: "#FFFFFF",
    color: "#1A7BA4",
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    padding: "6px 10px 6px 10px",
    borderRadius: "7px",
    border: "1px solid #1A7BA4",
    display: "flex",
    flexDirection: "row" as const,
    height: "36px",
    alignItems: "center",
  },
  deleteButton: {
    backgroundColor: "#FFFFFF",
    color: "#DC2626",
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    padding: "6px 10px 6px 10px",
    borderRadius: "7px",
    border: "1px solid #DC2626",
    display: "flex",
    flexDirection: "row" as const,
    height: "36px",
    alignItems: "center",
  },
  propertyItem: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "calc(50% - 150px)",
    margin: "19px 0px",
    alignItems: "center",
    display: "flex",
  },
  propertyItemAction: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "300px",
    display: "flex",
    flexDirection: "row",
    margin: "19px 0px",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px 10px",
  },
  tenantTitle: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "29px",
    color: "#0F172A",
  },
  tenantSubTitle: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "16px",
    fontWeight:400,
    lineHeight: "24px",
    color: "#64748B",
  },
  typographyTitleText: {    
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    marginLeft: "6px",
  },
  typographyText: {    
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
  },
};
// Customizable Area End

Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
// exports.logoText = "THE METER READING SERVICES";
// exports.termsAndConditionsText = "Terms & Conditions";
// exports.signUpText = "Sign up";
// exports.createAnAccountText = "Create an account to start using our services.";


// exports.zipCodeText = "Zip Code";
// exports.nameText = "Name";
// exports.emailText = "Email";
// exports.passwordText = "Password";
// exports.confirmPasswordText = "Confirm Password";

// exports.zipCodePlaceholderText = "10001";
// exports.namePlaceholderText = "Jhon Doe";
// exports.emailPlaceholderText = "email@example.com";
// exports.passwordPlaceholderText = "••••••••••";
// exports.confirmPasswordPlaceholderText = "••••••••••";

exports.addPropertyText = "Add property";
exports.enterPropertiydetailsText = "Enter the property details";
exports.propertyNameText = "Property name";
exports.serviceAddressText = "Service address";
exports.meterLocationText = "Meter location";

exports.buildingManagerText = "Building manager / Supt’s name";
exports.phoneNumberText = "Phone number";
exports.emailText = "Email";
exports.meterOfReadText = "# of meters to read";
exports.readingPerionText = "Reading period";
exports.startReadingFronText = "Start reading from";
exports.receiveReportText = "Receive report by";
exports.getInvoiceText = "Get invoice for my tenants";
exports.addServiceText = "Add service charge to invoice";
exports.cancelText = "Cancel";
exports.saveText = "Save";

// exports.alLeastSevenLlettersText = "At least 7 characters";
// exports.agreeoText = "I agree to the ";
// exports.alreadeyHaveAnAccountText = "Already have an account?";
// exports.logInText = "Log In";
// Customizable Area End
import React from "react";
// Customizable Area Start
import { Typography, Box, Select, MenuItem } from "@mui/material";
import { Formik } from "formik";
import {
  customerIcon,
  emailIcon,
  addressIcon,
  propertyIcon,
  rightArrowIcon,
  leftArrowIcon,
} from "./assets";
// Customizable Area End

import AddReadingPageController, { Props } from "./AddReadingPageController";
// Customizable Area Start
// Customizable Area End
export default class AddReadingPage extends AddReadingPageController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Box sx={webStyle.addReadingContainer}>
        <Box sx={webStyle.addReadingBlock}>
          <Box sx={webStyle.signUpFormContainer}>
            <Box sx={webStyle.addReadingTitle}>
              <Typography style={webStyle.formTitle}>Add a reading</Typography>
              <Typography style={webStyle.formSubTitle}>
                Choose the premise or the number of the meter you want to read
              </Typography>
            </Box>
            {this.state.step === 1 && (
              <Formik
                initialValues={{
                  premise: -1,
                  meterNumber: -1,
                }}
                onSubmit={() => {}}
                data-test-id="AddRadingStepOne"
              >
                {({ setFieldValue, values }) => (
                  <form>
                    <Box sx={webStyle.formContainer}>
                      <Box sx={webStyle.fieldControl}>
                        <label style={webStyle.inputLabel} htmlFor={"premise"}>
                          Premise
                        </label>
                        <Select
                          value={values.premise}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          data-test-id="premise"
                          style={
                            values.premise === -1
                              ? webStyle.selectOptionStart
                              : webStyle.selectOption
                          }
                          onChange={(event) => {
                            setFieldValue("premise", event.target.value);
                          }}
                        >
                          <MenuItem
                            value={-1}
                            style={webStyle.selectOptionStart}
                          >
                            Start typing...
                          </MenuItem>
                          {this.state.premise.map((p) => (
                            <MenuItem
                              value={p.id}
                              style={webStyle.selectOption}
                            >
                              {p.value}
                            </MenuItem>
                          ))}
                        </Select>
                      </Box>
                      <Box sx={webStyle.fieldControl}>
                        <label
                          style={webStyle.inputLabel}
                          htmlFor={"meterNumber"}
                        >
                          Meter number
                        </label>
                        <Select
                          value={values.meterNumber}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          data-test-id="meterNumber"
                          style={
                            values.meterNumber === -1
                              ? webStyle.selectOptionStart
                              : webStyle.selectOption
                          }
                          onChange={(event) => {
                            setFieldValue("meterNumber", event.target.value);
                            this.setSelectedReading(
                              values.premise,
                              Number(event.target.value)
                            );
                          }}
                        >
                          <MenuItem
                            value={-1}
                            style={webStyle.selectOptionStart}
                          >
                            Start typing...
                          </MenuItem>
                          {this.state.meterNumber
                            .filter((mN) => mN.premiseId === values.premise)
                            .map((mN) => (
                              <MenuItem
                                value={mN.id}
                                style={webStyle.selectOption}
                              >
                                {mN.value}
                              </MenuItem>
                            ))}
                        </Select>
                      </Box>
                      <Box sx={webStyle.fieldControl}>
                        <Box sx={webStyle.informationBlock}>
                          <Box sx={webStyle.informationFieldBlock}>
                            <Box sx={webStyle.informationTitleContainer}>
                              <img
                                src={customerIcon}
                                style={webStyle.infoIcon}
                              />
                              <Typography style={webStyle.informationTitle}>
                                Customer
                              </Typography>
                            </Box>
                            <Typography style={webStyle.informationField}>
                              {this.state.selectedReading?.customer || "-"}
                            </Typography>
                          </Box>
                          <Box sx={webStyle.informationFieldBlock}>
                            <Box sx={webStyle.informationTitleContainer}>
                              <img
                                src={propertyIcon}
                                style={webStyle.infoIcon}
                              />
                              <Typography style={webStyle.informationTitle}>
                                Property
                              </Typography>
                            </Box>
                            <Typography style={webStyle.informationField}>
                              {this.state.selectedReading?.property || "-"}
                            </Typography>
                          </Box>
                          <Box sx={webStyle.informationFieldBlock}>
                            <Box sx={webStyle.informationTitleContainer}>
                              <img
                                src={addressIcon}
                                style={webStyle.infoIcon}
                              />
                              <Typography style={webStyle.informationTitle}>
                                Address
                              </Typography>
                            </Box>
                            {this.state.selectedReading?.address.map((ads) => (
                              <Typography style={webStyle.informationField}>
                                {ads}
                              </Typography>
                            )) || "-"}
                          </Box>
                          <Box sx={webStyle.informationFieldBlock}>
                            <Box sx={webStyle.informationTitleContainer}>
                              <img src={emailIcon} style={webStyle.infoIcon} />
                              <Typography style={webStyle.informationTitle}>
                                Email
                              </Typography>
                            </Box>
                            {this.state.selectedReading?.email.map((eml) => (
                              <Typography style={webStyle.informationField}>
                                {eml}
                              </Typography>
                            )) || "-"}
                          </Box>
                        </Box>
                      </Box>
                      <Box sx={webStyle.fieldControlFirstStepGroup}>
                        <button
                          style={webStyle.submitButton}
                          data-test-id="submitBtn"
                          type="button"
                          onClick={() => {
                            this.setStep(2);
                          }}
                        >
                          <Typography sx={webStyle.buttonText}>Next</Typography>
                          <img src={rightArrowIcon} style={webStyle.infoIcon} />
                        </button>
                      </Box>
                    </Box>
                  </form>
                )}
              </Formik>
            )}
            {this.state.step === 2 && (
              <Formik
                initialValues={{
                  premise: null,
                  meterNumber: null,
                  prevReading: this.state.selectedReading?.prevReading || "333",
                  currReading: "",
                  consumption: "",
                  note: "",
                }}
                validateOnMount={true}
                validateOnChange={true}
                onSubmit={() => {}}
                data-test-id="AddRadingStepTwo"
              >
                {({ values, setFieldValue }) => (
                  <form>
                    <Box sx={webStyle.formContainer}>
                      <Box sx={webStyle.fieldControl}>
                        <Box sx={webStyle.prevTitle}>
                          <Box sx={webStyle.prevEmptyTitle}>
                            <label
                              style={webStyle.inputLabel}
                              htmlFor={"prevReading"}
                            >
                              Previous reading
                            </label>
                            {this.state.selectedReading?.prevReading === "" && (
                              <label
                                style={webStyle.aditionInputLabel}
                                htmlFor={"prevReading"}
                              >
                                - Not available
                              </label>
                            )}
                          </Box>
                          {this.state.selectedReading?.prevReadingDate && (
                            <label
                              style={webStyle.aditionInputLabel}
                              htmlFor={"prevReading"}
                            >
                              {this.state.selectedReading?.prevReadingDate}
                            </label>
                          )}
                        </Box>
                        {this.state.selectedReading?.prevReading && (
                          <input
                            style={webStyle.inputDisabledElement}
                            data-test-id="prevReading"
                            type="text"
                            value={values.prevReading}
                            disabled
                          />
                        )}
                      </Box>
                      <Box sx={webStyle.fieldControl}>
                        <label
                          style={webStyle.inputLabel}
                          htmlFor={"currReading"}
                        >
                          Current reading
                        </label>
                        <input
                          style={webStyle.inputElement}
                          data-test-id="currReading"
                          type="text"
                          value={values.currReading}
                          onChange={(event) => {
                            const currentReading = Number(event.target.value);
                            const previuslyReading = Number(values.prevReading);
                            this.setNoteeError(false);
                            setFieldValue("currReading", event.target.value);
                            setFieldValue(
                              "consumption",
                              currentReading - previuslyReading
                            );
                          }}
                        />
                      </Box>
                      <Box sx={webStyle.fieldControl}>
                        <label
                          style={webStyle.inputLabel}
                          htmlFor={"consumption"}
                        >
                          Consumption
                        </label>
                        <input
                          style={webStyle.inputDisabledElement}
                          data-test-id="consumption"
                          type="text"
                          disabled
                          value={values.consumption}
                        />
                      </Box>
                      <Box sx={webStyle.fieldControl}>
                        <label style={webStyle.inputLabel} htmlFor={"note"}>
                          Note
                        </label>
                        <textarea
                          style={
                            this.state.error
                              ? webStyle.textAreaErrorElement
                              : webStyle.textAreaElement
                          }
                          data-test-id="note"
                          onChange={() => {
                            this.setNoteeError(false);
                          }}
                        />
                        <Typography
                          style={
                            this.state.error
                              ? webStyle.textAreaErrorComent
                              : webStyle.textAreaComent
                          }
                        >
                          Enter a note if you’re not able to enter the reading
                        </Typography>
                      </Box>
                      <Box sx={webStyle.fieldControlSecontStepGroup}>
                        <button
                          style={webStyle.prevButton}
                          data-test-id="cancelBtn"
                          type="button"
                          onClick={() => {
                            this.setStep(1);
                          }}
                        >
                          <img src={leftArrowIcon} style={webStyle.infoIcon} />
                          <Typography sx={webStyle.buttonText}>Prev</Typography>
                        </button>
                        <button
                          style={webStyle.submitButton}
                          data-test-id="submitBtn"
                          type="button"
                          onClick={() => {
                            if (values.currReading) {
                              this.setCurrentReading();
                            } else {
                              if (values.note) {
                                this.setNote();
                              } else {
                                this.setNoteeError(true);
                              }
                            }
                          }}
                        >
                          <Typography sx={webStyle.buttonText}>
                            Submit
                          </Typography>
                        </button>
                      </Box>
                    </Box>
                  </form>
                )}
              </Formik>
            )}
          </Box>
        </Box>
      </Box>

      //Merge Engine End DefaultContainer
      // Customizable Area Start
    );
  }
}
// Customizable Area Start
const webStyle = {
  addReadingContainer: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#F8FAFC",
  },
  addReadingBlock: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  signUpFormContainer: {
    margin: "auto",
    maxWidth: "600px",
    width: "80%",
  },
  addReadingTitle: {
    margin: "20px 10px",
  },
  formContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  formTitle: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    marginBottom: "15px",
  },
  formSubTitle: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#475467",
    marginBottom: "20px",
  },
  fieldControl: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    margin: "10px",
    position: "relative",
  },
  prevTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  prevEmptyTitle: {
    display: "flex",
    flexDirection: "row",
  },
  fieldControlFirstStepGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  fieldControlSecontStepGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  inputLabel: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#334155",
    marginBottom: "5px",
    alignItems: "center",
    display: "flex",
  },
  aditionInputLabel: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#334155",
    marginBottom: "5px",
    alignItems: "center",
    display: "flex",
  },
  inputElement: {
    height: "56px",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
  },
  inputDisabledElement: {
    height: "56px",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    backgroundColor: "#F1F5F9",
  },
  textAreaElement: {
    height: "139px",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
  },
  textAreaErrorElement: {
    height: "139px",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #F87171",
  },
  textAreaComent: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#94A3B8",
    marginTop: "5px",
    marginBottom: "5px",
  },
  textAreaErrorComent: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#F87171",
    marginTop: "5px",
    marginBottom: "5px",
  },
  selectOptionStart: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#94A3B8",
  },
  selectOption: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#0F172A",
  },
  prevButton: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    margin: "15px",
    height: "56px",
    borderRadius: "8px",
    border: "1px solid #1A7BA4",
    background: "#FFFFFF",
    color: "#1A7BA4",
    padding: "0px 20px",
    flexDirection: "row" as const,
    display: "flex",
    alignItems: "center",
  },
  submitButton: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    margin: "15px",
    height: "56px",
    borderRadius: "8px",
    border: "none",
    background: "#1A7BA4",
    color: "#FFFFFF",
    padding: "0px 20px",
    flexDirection: "row" as const,
    display: "flex",
    alignItems: "center",
  },
  buttonText: {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
  },
  informationBlock: {
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
  },
  informationFieldBlock: {
    margin: "10px 5px",
  },
  informationTitle: {
    color: "#94A3B8",
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "24px",
    alignItems: "center",
    display: "flex",
    margin: "0px 5px",
  },
  informationField: {
    color: "#0F172A",
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "24px",
  },
  informationTitleContainer: {
    display: "flex",
    flexDirection: "row",
    padding: "5px 0px",
  },
  infoIcon: {
    width: "20px",
    height: "20px",
  },
};
// Customizable Area End

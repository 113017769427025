Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpDeleteMethod = "DELETE";

exports.contactUsApiContentType = "application/json";
exports.getContactUsAPiEndPoint = "contact_us/contacts";

exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";
exports.errorEmailNotValid = "Email not valid.";
exports.phoneNumberError = "Phone number not valid"

exports.nameTitle = "Name";
exports.emailTitle = "Email";
exports.numberTitle = "Phone number";
exports.commentsTitle = "Comments";

exports.namePlaceHolder = "Enter Your Name";
exports.emailPlaceHolder = "Enter Your Email";
exports.numberPlaceHolder = "Enter Phone number";
exports.commentsPlaceHolder = "Enter Comments";

exports.labelTitleText = "contactus";
exports.labelBodyText = "contactus Body";

exports.btnExampleTitle = "CLICK ME";

exports.headerText = "CONTACT US FOR OUR MAIN METER READING SERVICES IN BROOKLYN, NY";
exports.subHeaderServicesLinkText = "Services"
exports.subHeaderText = "offered to our customers are main meter reading services in Brooklyn, NY, customized tenant invoices, sub-meter reading services, verifying accuracy of city bills, and more.";

exports.detailHeaderText = "Please phone, email, fax or leave us a message and we will be happy to get back to you!";
exports.detailPhoneLabel = "Phone";
exports.detailPhone1Value = "718-756-4123";
exports.detailPhone2Value = "212-673-8920";
exports.detailFaxLabel = "Fax";
exports.detailFaxValue = "718-773-2900";
exports.detailEmailLabel = "Email";
exports.detailEmailValue = "info@meterreading.nyc";
exports.detailLocationLabel = "Location";
exports.detailLocationValue = "1056 E 103rd St, Brooklyn, NY Zip 11236";

exports.formNameLabel = "Name";
exports.formEmailLabel = "Email";
exports.formPhoneLabel = "Phone";
exports.formServicesNeededLabel = "Services Needed";
exports.formCommentsLabel = "Comments / Message";
exports.formButtonText = "Submit";
// Customizable Area End
Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";

exports.backtoPropertiesText = "Back to properties";
exports.addMeterText = "Add meter";
exports.addTeenantText = "Add tenant";
exports.tenantNameText = "Tenant name";
exports.numberOfMetersText = "# of meters";
exports.actionsText = "Actions";
exports.editText = "Edit";
exports.deleteText = "Delete";
exports.serachPlaceholderText = "Search";
// Customizable Area End
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface Tenant {
  id: string;
  tenantName: string;
  countMeter: number;
}

interface S {
  tenantList: Tenant[]
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class TenantPageController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [];

    this.state = {
      tenantList: [
        {
          id: "1",
          tenantName: "New Hyde park, NY 11040",
          countMeter: 15,
        },
        {
          id: "2",
          tenantName: "New Hyde park, NY 11040",
          countMeter: 15,
        },
        {
          id: "3",
          tenantName: "New Hyde park, NY 11040",
          countMeter: 15,
        },
        {
          id: "4",
          tenantName: "New Hyde park, NY 11040",
          countMeter: 15,
        },
        {
          id: "5",
          tenantName: "New Hyde park, NY 11040",
          countMeter: 15,
        },
      ]
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  searchProperties = (search: string) => {

  }

  addTenant = () => {

  }

  addMeter = (id: string) => {

  }

  editTenant = (id: string) => {
    
  }

  deleteTenant = (id: string) => {
    
  }

  goBack = () => {
    this.props.navigation.navigate("PropertiesPage");
  }

  // Customizable Area Start
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  // Customizable Area End
}

Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";

exports.typeText = "Type of meter";
exports.meterNumberText = "Meter numbeer";
exports.lastReadingText = "Last reading";
exports.lastReadingDateText = "Date of last reading";
exports.addNewMeterText = "Add meter";

exports.meterTypeText = "Water";
exports.electricalTypeText = "Electrical";
exports.meterTitleText = "Meter #";
exports.cancelBtnText = "Cancel";
exports.saveBtnText = "Save";
exports.tenantNameText = "Tenant name";
// Customizable Area End
Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";

exports.searchPlaceholderText = "Search";
exports.backToTenantText = "Back to tenants";
exports.addMeterText = "Add Meter";
exports.meterNumberText = "Meter Number";
exports.typeText = "Type";
exports.lastReadingText = "Last reading";
exports.lastReadingDateText = "Date of Last reading";
exports.actionsText = "Actions";
exports.editText = "Edit";
exports.deleteText = "Delete";
// Customizable Area End
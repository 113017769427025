import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface Property {
  id: string;
  propertysName: string;
  propertysAddress: string;
  countTenant: number;
  countMeter: number;
}

interface S {
  propertiesList: Property[]
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class PropertiesPageController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [];

    this.state = {
      propertiesList: [
        {
          id: "1",
          propertysName: "Abstergo Ltd.",
          propertysAddress: "New Hyde park, NY 11040",
          countTenant: 15,
          countMeter: 4,
        },
        {
          id: "2",
          propertysName: "Big Kahuna",
          propertysAddress: "3605 Parker Rd., NY 11923",
          countTenant: 0,
          countMeter: 1,
        },
        {
          id: "3",
          propertysName: "Biffco Enterprises Ltd.",
          propertysAddress: "New Hyde park, NY 11040",
          countTenant: 5,
          countMeter: 2,
        },
        {
          id: "4",
          propertysName: "Acme Co.",
          propertysAddress: "3605 Parker Rd., NY 10123",
          countTenant: 4,
          countMeter: 1,
        },
        {
          id: "5",
          propertysName: "Big Kahuna",
          propertysAddress: "New Hyde park, NY 11040",
          countTenant: 5,
          countMeter: 3,
        },
        {
          id: "6",
          propertysName: "Biffco Enterprises",
          propertysAddress: "New Hyde park, NY 11040",
          countTenant: 15,
          countMeter: 4,
        },
        {
          id: "7",
          propertysName: "Binford Ltd.",
          propertysAddress: "3605 Parker Rd., NY 11923",
          countTenant: 0,
          countMeter: 1,
        },
        {
          id: "8",
          propertysName: "Acme Co.",
          propertysAddress: "New Hyde park, NY 11040",
          countTenant: 5,
          countMeter: 2,
        },
        {
          id: "9",
          propertysName: "Barone LLC.",
          propertysAddress: "3605 Parker Rd., NY 10123",
          countTenant: 4,
          countMeter: 1,
        },
        {
          id: "10",
          propertysName: "Binford Ltd.",
          propertysAddress: "New Hyde park, NY 11040",
          countTenant: 5,
          countMeter: 3,
        },
        {
          id: "11",
          propertysName: "Abstergo Ltd.",
          propertysAddress: "New Hyde park, NY 11040",
          countTenant: 15,
          countMeter: 4,
        },
        {
          id: "12",
          propertysName: "Big Kahuna",
          propertysAddress: "3605 Parker Rd., NY 11923",
          countTenant: 0,
          countMeter: 1,
        },
        {
          id: "13",
          propertysName: "Biffco Enterprises Ltd.",
          propertysAddress: "New Hyde park, NY 11040",
          countTenant: 5,
          countMeter: 2,
        },
        {
          id: "14",
          propertysName: "Acme Co.",
          propertysAddress: "3605 Parker Rd., NY 10123",
          countTenant: 4,
          countMeter: 1,
        },
        {
          id: "15",
          propertysName: "Big Kahuna",
          propertysAddress: "New Hyde park, NY 11040",
          countTenant: 5,
          countMeter: 3,
        },
        {
          id: "16",
          propertysName: "Biffco Enterprises",
          propertysAddress: "New Hyde park, NY 11040",
          countTenant: 15,
          countMeter: 4,
        },
        {
          id: "17",
          propertysName: "Binford Ltd.",
          propertysAddress: "3605 Parker Rd., NY 11923",
          countTenant: 0,
          countMeter: 1,
        },
        {
          id: "18",
          propertysName: "Acme Co.",
          propertysAddress: "New Hyde park, NY 11040",
          countTenant: 5,
          countMeter: 2,
        },
        {
          id: "19",
          propertysName: "Barone LLC.",
          propertysAddress: "3605 Parker Rd., NY 10123",
          countTenant: 4,
          countMeter: 1,
        },
        {
          id: "20",
          propertysName: "Binford Ltd.",
          propertysAddress: "New Hyde park, NY 11040",
          countTenant: 5,
          countMeter: 3,
        },
      ]
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  searchProperties = (search: string) => {

  }

  filterProperties = () => {

  }

  addProperties = () => {
    this.props.navigation.navigate("AddPropertiesPage");
  }

  addTenant = (id: string) => {

  }

  editProperties = (id: string) => {
    
  }

  deletePropeerties = (id: string) => {
    
  }

  // Customizable Area Start
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  // Customizable Area End
}
